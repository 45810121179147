<template>
    <div>
        <ayl-berad-nav :nav="nav"></ayl-berad-nav>
        <div class="content-box">
            <div class="content-main">
                <el-form :model="form" :rules="rules" ref="form" :inline="true" :label-position="$config.labelPosition"
                    :label-width="$config.labelWidth">
                    <div class="form-label-base">发放工资</div>
                    <el-form-item label="工资名称" prop="salaryName">
                        <el-input v-model="form.salaryName" placeholder="请输入" maxlength="25" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="工资总额" prop="salaryTotal">
                        <el-input v-model="form.salaryTotal" placeholder="请输入" maxlength="25" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="标段名称" prop="bidId">
                        <el-cascader v-model="form.bidId" placeholder="请选择" :options=common_getBidNameAndIdList
                            @change="getDistinctBid" :show-all-levels="false" filterable disabled>
                        </el-cascader>
                    </el-form-item>
                    <el-form-item label="项目组：">
                        <span>{{ form.projectGroupId }}</span>
                    </el-form-item>
                    <el-form-item label="工资月份" prop="salaryMonth">
                        <el-date-picker v-model="form.salaryMonth" type="month" value-format="yyyyMM" placeholder="选择月"
                            disabled>
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="费用周期" prop="salaryDate">
                        <el-date-picker v-model="form.salaryDate" unlink-panels type="daterange" format="yyyy-MM-dd"
                            :default-time="['00:00:00', '23:59:59']" value-format="timestamp" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" disabled>
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="附件" style="width: 100%">
                        <ayl-upload-files v-model="form.attachmentUrls" :limit="0" :maxSize="10240" :accept="''"
                            disabled></ayl-upload-files>
                    </el-form-item>
                    <el-divider></el-divider>
                    <div class="form-label-base">员工工资发放</div>
                    <!-- 工资表 -->
                    <el-table :data="allNewTableData" class="mt20px" style="width: 100%">
                        <el-table-column prop="name" label="姓名" width="80">
                        </el-table-column>
                        <el-table-column prop="idCardNumber" label="身份证" width="180">
                        </el-table-column>
                        <el-table-column prop="personTypeDes" label="岗位" width="120">
                        </el-table-column>
                        <el-table-column prop="dutyDays" label="出勤天数" width="100">
                        </el-table-column>
                        <el-table-column prop="wageStandard" label="标准工资" width="100">
                        </el-table-column>
                        <el-table-column label="平时加班" width="120">
                            <el-table-column prop="usualOvertimeHours" label="小时" width="60">
                            </el-table-column>
                            <el-table-column prop="usualOvertimeWage" label="工资" width="60">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="休息日加班" width="140">
                            <el-table-column prop="weekendOvertimeHours" label="小时" width="70">
                            </el-table-column>
                            <el-table-column prop="weekendOvertimeWage" label="工资" width="70">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="节假日加班" width="120">
                            <el-table-column prop="holidayOvertimeHours" label="小时" width="60">
                            </el-table-column>
                            <el-table-column prop="holidayOvertimeWage" label="工资" width="60">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column prop="wageSummary" label="工资小计" width="100">
                        </el-table-column>
                        <el-table-column label="福利" width="250">
                            <el-table-column prop="welfareAllowance" label="岗位津贴" width="70">
                            </el-table-column>
                            <el-table-column prop="welfareSubsidy" label="补贴" width="60">
                            </el-table-column>
                            <el-table-column prop="welfareSummer" label="高温费" width="60">
                            </el-table-column>
                            <el-table-column prop="welfareSummary" label="小计" width="60">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column prop="wageShoud" label="应发工资" width="120">
                        </el-table-column>
                        <el-table-column label="扣款" width="390">
                            <el-table-column prop="deductionSocialSecurity" label="社保" width="60">
                            </el-table-column>
                            <el-table-column prop="deductionCasualty" label="意外险" width="60">
                            </el-table-column>
                            <el-table-column prop="deductionEmployer" label="雇主险" width="60">
                            </el-table-column>
                            <el-table-column prop="deductionIncomeTax" label="个税" width="60">
                            </el-table-column>
                            <el-table-column prop="deductionHaf" label="住房公积金" width="90">
                            </el-table-column>
                            <el-table-column prop="deductionSummary" label="合计" width="60">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column prop="wageReal" label="实发工资" width="120">
                        </el-table-column>
                        <el-table-column prop="salaryRemark" label="备注" width="120">
                        </el-table-column>
                    </el-table>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        BUS,
        BUSEVENT
    } from "@/components/global/bus.js";
    export default {
        data() {
            const vm = this
            return {
                nav: [{
                    name: "发放工资"
                }],
                submitLoading: false,
                common_getBidNameAndIdList: [],
                personnelTableDialog: false,
                //弹窗有关
                viewUrlForDialog: ``,
                isShowDialog: false,
                dataForDialog: null,
                //勾选列表获取个人信息
                newTableData: [],
                allTableData: [],
                allNewTableData: [], //表格data
                form: {
                    bidId: null,
                    salaryName: null, //工资名称
                    salaryTotal: null, //工资总额
                    bidName: null, //标段名称
                    salaryMonth: null, //工资月份
                    projectGroupId: null, //项目组
                    salaryDate: [],
                    salaryStartDate: null, //费用开始周期
                    salaryEndDate: null, //费用结束周期
                    attachmentUrls: [{
                        url: null
                    }], //附件信息
                },
                // 必填字段校验
                rules: {
                    salaryName: [{
                        required: true,
                        message: "请输入工资名称",
                        trigger: "blur"
                    }],
                    salaryTotal: [{
                        required: true,
                        message: "请输入工资总额",
                        trigger: "blur"
                    }],
                    bidId: {
                        required: true,
                        message: "请选择标段名称",
                        trigger: "change"
                    },
                    salaryMonth: {
                        required: true,
                        message: "请选择工资月份",
                        trigger: "change"
                    },
                    salaryDate: {
                        required: true,
                        message: "请选择费用周期",
                        trigger: "change"
                    },
                },
                //人员列表
                table: {
                    api: vm.$api_hw.personnelInfoList,
                    query: {
                        personTypes: null, //人员类型列表  
                    },
                    // 是否显示分页
                    // hidePagination: true,
                    // 表格查询的条件组件
                    searchData: [{
                        type: 'multiple',
                        title: '人员类型',
                        model: 'personTypes',
                        placeholder: '请选择',
                        option: [],
                        width: '200px'
                    }, ],
                    columns: [{
                            type: 'selection',
                            width: '50px',
                        },
                        {
                            title: '序号',
                            width: '50px',
                            $index: 'index',
                        },
                        {
                            title: '姓名',
                            key: 'name',
                            width: '120px',
                        }, {
                            title: '身份证号码',
                            showTooltip: true,
                            key: 'bidName',
                        }, {
                            title: '人员类型',
                            showTooltip: true,
                            render(h, ctx) {
                                return h("span", vm.personType(ctx.row.personType));
                            }
                        }, {
                            title: '所属企业',
                            width: '140px',
                            key: 'companyId',
                        }
                    ]
                },
            };
        },
        methods: {
            //根据租户信息获取标段信息
            async getDistinctBid(val) {
                let projectTeam = await this.$api_hw.districtInfo_bidManageGet({
                    id: val[1]
                })
                this.form.projectGroupId = projectTeam.projectGroupId
            },


            // 人员类型
            personType(res) {
                switch (res) {
                    case 0:
                        return '项目经理'
                    case 1:
                        return '项目副经理'
                    case 2:
                        return '片区负责人'
                    case 3:
                        return '车队长'
                    case 4:
                        return '小组长'
                    case 5:
                        return '安全生产管理员'
                    case 6:
                        return '一线保洁人员'
                    case 7:
                        return '垃圾收集工'
                    case 8:
                        return '设备操作工'
                    case 9:
                        return '扫路车司机'
                    case 10:
                        return '清洗车司机'
                    case 11:
                        return '垃圾收集车司机'
                    case 12:
                        return '钩臂车司机'
                    case 13:
                        return '平板车司机'
                    case 14:
                        return '公厕保洁员'
                    case 15:
                        return '水电工'
                    case 16:
                        return '其它'
                }
            }
        },

        async mounted() {
            this.common_getBidNameAndIdList = await this.$api_hw.common_getBidNameAndIdList({})
            let form = await this.$api_hw.salary_getBySalaryId({
                id: this.$route.query.id,
            })
            this.form.bidId = form.bidId
            this.form.salaryName = form.salaryName,
                this.form.salaryTotal = form.salaryTotal, //工资总额
                this.form.bidName = form.bidName, //标段名称
                this.form.salaryMonth = form.salaryMonth, //工资月份
                this.form.projectGroupId = form.projectGroupId, //项目组
                this.form.salaryDate = [this.$dayjs(form.salaryStartDate), this.$dayjs(form.salaryEndDate)]
            this.allNewTableData = form.details
            this.form.attachmentUrls[0].url = form.attachmentUrls
            console.log(this.form.attachmentUrls)
        },
    };
</script>

<style lang='sass' scoped>
.content-box 
  padding-top: 20px

.form-label-base
  border-left: 2px solid $-color-primary-3
  padding-left: 8px
/deep/ .el-button--primary
    background-color: $-color-primary-3
    border-color: $-color-primary-3
/deep/.el-button--success:active 
    background: $-color-primary-3
    border-color: $-color-primary-3
/deep/.el-table thead.is-group th
    color: #2E3033
/deep/.el-button--text
    color: $-color-primary-3
/deep/.el-checkbox__input.is-checked .el-checkbox__inner
    background-color: $-color-primary-3
    border-color: $-color-primary-3
/deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner
    background-color: $-color-primary-3
    border-color: $-color-primary-3
/deep/.el-button--success 
    background-color: $-color-primary-3
    border-color: $-color-primary-3
/deep/.el-checkbox__inner:hover 
    border-color: $-color-primary-3

.btn
    height: 28px
    border: 1px solid $-color-primary-3
    border-radius: 4px
    color: $-color-primary-3
</style>